export const Globals = {
    company : {
        name : "Databyze",
        email : "databyze@gmail.com",
        phone_code : "+971",
        phone : " 55 137 4295",
        address: "Dubai, United Arab Emirates",
    }

   
}