// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore, addDoc, collection, query, deleteDoc, getDocs, where, doc,getDoc, setDoc, updateDoc, limit, startAfter } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDq0VHQfsEZ8JQsB0KUTAGFSJS9KJ2Y5kw",
  authDomain: "databyze-46c09.firebaseapp.com",
  projectId: "databyze-46c09",
  storageBucket: "databyze-46c09.appspot.com",
  messagingSenderId: "852896904237",
  appId: "1:852896904237:web:df49977ce30f143b4174a4",
  measurementId: "G-0DR6HZQGPE"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


// Ensure authentication persists across reloads
setPersistence(auth, browserSessionPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

const analytics = getAnalytics(app);


export { db,auth, collection, query, getDocs,deleteDoc,  where, limit, startAfter,doc, getDoc,addDoc, setDoc, updateDoc };
