import React, { useState, useRef, useEffect } from "react";
import { pageTitle } from "../PageTitle";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import contact from "../../assets/images/resource/contact.jpg";
import { Globals } from "../../Globals.js";
import { db, collection, addDoc } from "../../config/FirebaseConfig.js";

const Contact = () => {
  pageTitle("Contact");

  // Reference to the contact form
  const formRef = useRef(null);

  // Scroll to the form when the page loads
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []); // Empty dependency array ensures this runs once after the component mounts

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [formStatus, setFormStatus] = useState({
    loading: false,
    message: "",
    error: false,
  });

  // Input validation
  const validateForm = () => {
    const { name, email, phone } = formData;
    if (!name || !email || !phone) {
      return "All fields are required.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return null;
  };

  // Handle form submission
  const handleSubmitOne = async (e) => {
    e.preventDefault();

    const error = validateForm();
    if (error) {
      setFormStatus({
        loading: false,
        message: error,
        error: true,
      });
      return;
    }

    try {
      setFormStatus({ loading: true, message: "", error: false });

      // Add document to Firebase Firestore
      await addDoc(collection(db, "contacts"), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        createdAt: new Date(),
      });

      // Reset form and display success message
      setFormData({ name: "", email: "", phone: "" });
      setFormStatus({
        loading: false,
        message: "Your message has been sent successfully!",
        error: false,
      });
    } catch (error) {
      console.error("Error adding document:", error);
      setFormStatus({
        loading: false,
        message: "Failed to send your message. Please try again later.",
        error: true,
      });
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <section className="contact__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-ms-12">
              <div className="contact__left">
                <figure>
                  <img src={contact} alt="" />
                </figure>
                <h4>Contact Information</h4>
                <div className="contact__info">
                  <div className="contact__block">
                    <div className="icon">
                      <i className="icon-19"></i>
                    </div>
                    <div className="contact__text">
                      <Link to="tell:3025550107">
                        ({Globals.company.phone_code}) {Globals.company.phone}
                      </Link>{" "}
                      <br />
                    </div>
                  </div>
                  <div className="contact__block">
                    <div className="icon two">
                      <i className="icon-20"></i>
                    </div>
                    <div className="contact__text">
                      <Link to="mailto:willie.jennings@example.com">
                        {Globals.company.email}
                      </Link>{" "}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              {/* Assigning ref to this div */}
              <div className="contact__right" ref={formRef}>
                <div className="form__title">
                  <div className="title__data">
                    <div className="sub__title">
                      <h4>Contact Us</h4>
                    </div>
                    <div className="title">
                      <h2>Get a Free Quote</h2>
                    </div>
                  </div>
                </div>
                <div className="form-inner">
                  <form onSubmit={handleSubmitOne} className="default-form">
                    <div className="row clearfix">
                      <div className="col-xl-6 form-group">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name *"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email *"
                          value={formData.email}
                          style={{ textTransform: "none" }}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="ccol-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Phone *"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-xl-12 form-group message-btn btn-box">
                        <button
                          className="theme-btn theme-btn-one"
                          type="submit"
                          disabled={formStatus.loading}
                        >
                          {formStatus.loading
                            ? "Loading..."
                            : "Send your Message"}
                        </button>
                      </div>
                    </div>
                    {formStatus.message && (
                      <p style={{ color: formStatus.error ? "red" : "green" }}>
                        {formStatus.message}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
